import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/7/animation.gif'
import mobileImg from '../../assets/blog/7/mobile.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Kdo slaví konec roku v březnu?" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Kdo slaví konec roku v březnu?" />
        <ContentSubPage>
          <p>
            Pokud vám se začátkem března na mysli tane spíše daňové přiznání než
            probouzející se příroda, jste tady správně. Nejspíš totiž budete
            spadat do kategorie odvážlivců označovaných „přitažlivým“ názvem
            OSVČ. Jedna věc je totiž obstarat a pohlídat záležitosti osobního
            nebo rodinného života, a jiná průběžně si hlídat povinnosti spojené
            s živností. Faktury, povinná pojištění, výdaje, daně, termíny.
            Marketing, PR, školení a spousta dalšího. Ať už máte nebo nemáte
            externí výpomoc v podobě účetní či specialisty na reklamu, pořád by
            se vám ještě často hodil asistent, který by za vás vše ohlídal a
            vyřídil. To už je ale většinou za hranicí našich možností a tak se
            vše snažíme zvládat sami. Kalendář, diář, seznamy v mobilu,
            organizační apky, denní seznamy úkolů po kapsách… připomíná vám to
            něco? Vsadíme se, že sami používáte minimálně dva z těchto nástrojů,
            které podepírají naši paměť tam, kde to prostě nejde všechno
            pojmout. A dva už je moc.
          </p>
          <p>
            V různých studiích bylo prokázáno, že lidé na volné noze zažívají
            více pracovního stresu než zaměstnanci. Je tedy potřeba redukovat
            starosti všude tam, kde to je možné. Jedním ze způsobů, jak
            dlouhodobě snížit hladinu adrenalinu, je konzistentně používat
            takovou aplikaci pro organizaci, kalendář, připomínky a komunikaci,
            která bude nabízet vše, co potřebujete, a navíc se naprosto
            přizpůsobí vašim specifickým potřebám a zvykům.
          </p>
          <p>
            Až si takovou najdete a rychle vyřešíte evidenci toho, co, jak se
            říká, hoří nejvíc, nastane úleva. A taky ten správný moment zamyslet
            se nad záležitostmi, které tak urgentní nebyly, a proto pravidelně
            končily na pomyslném seznamu odložených úkolů. K naší vlastní škodě
            ovšem. Přeci jen, je jaro. A k životu se probouzí i spotřebitel v
            každém z nás. Ideální čas nastudovat konkurenci, naplánovat
            propagaci nebo se ozvat potenciálním klientům nebo spolupracovníkům,
            které už dlouho chcete oslovit. Teď na to totiž budete mít čas i
            mentální kapacitu.
          </p>
          <p className="pt-6">
            Můžete třeba využít aplikaci Flagis, efektivní nástroj pro
            organizaci práce, povinností i volného času. Získáte tak
            spolehlivého asistenta, který za vás bude hlídat termíny, úkoly,
            spravovat poznámky a vy budete mít vše vždy po ruce a pod kontrolou.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={mobileImg} target="_blank" rel="noreferrer">
              <img
                src={mobileImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
